import { useState } from 'react';
import { useTranslation } from "react-i18next";
import MainMenu from './MainMenu';

import Button from '../content/Button';
import Text from '../content/Text';
import style from '../../assets/scss/components/global/header.module.scss'
import chevronDown from '@ingka/ssr-icon/paths/menu'
import cross from '@ingka/ssr-icon/paths/cross'
import documentAdd from '@ingka/ssr-icon/paths/document-add'
import loguoutIcon from '@ingka/ssr-icon/paths/arrow-right-from-base'

import { useSelector, useDispatch } from "react-redux";
import { changeLang, doLogout, updateFilterHfb, updateFlowSelected } from '../../store/globalSlice';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Modal, { Sheets, ModalHeader, ModalBody } from "@ingka/modal";
import SelectIngka from '../form/SelectIngka';
import Info from './Info';
import eventEmit from '../../utils/Analytics';

export default function Header() {
  const[clicked, setClicked] = useState(false)
  const [t, i18n] = useTranslation("global")
  const flows = useSelector((state) => state.flows);
  const lang = useSelector((state) => state.global.language)
  const languages = [{name: t("language.labelEs"), value: "es"}, {name: t("language.labelEn"), value: "en"}]
  const dispatch = useDispatch();
  const hfb = useSelector((state) => state.global.filtersSelected.hfb)
  const defaultHfb = useSelector((state) => state.filters.hfbs[0])
  
  const changeLanguage = (e)=>{
    dispatch(changeLang(e.target.value))
    i18n.changeLanguage(e.target.value)
    window.location.reload(false);
  }

  const toggleMenu = ()=>{
    setClicked(!clicked)
  }

  const navigate = useNavigate()
  const logout = ()=>{
    dispatch(doLogout())
    navigate('/login', {replace: true})
  }

  //select flow modal
  const[modalSelectFlowOpen, setModalSelectFlowOpen] = useState(false)
  const flowSelected = useSelector((state) => state.global.flowSelected)
  const openSelectModal = ()=>{
    if(flowSelected == null){
      setModalSelectFlowOpen(!modalSelectFlowOpen)
    }else{
      navigate('/new-action')
    }
  }


  //save flow in store
  const updateFlow = (flow)=>{
    if(hfb === undefined || hfb === null) dispatch(updateFilterHfb(defaultHfb))
    dispatch(updateFlowSelected(flow))
    setModalSelectFlowOpen(!modalSelectFlowOpen)
    navigate('/new-action')
  }

  let location =  useLocation();
  const path = location.pathname
  return (
    <>
      <div className={style.floatButton}>
        {path !== "/new-action" &&
          <Button 
            type="emphasised" 
            iconOnly 
            size="small" 
            ssrIcon={documentAdd} 
            className={style.new_action}
            onClick={openSelectModal}
          />
        }
        
      </div>
      <div className={style.header} >
        <div className='container-header'>
          <div className={style.left}>
            <Button 
              type="tertiary" 
              iconOnly 
              size="small"
              ssrIcon={chevronDown} 
              className={style.ham_btn}
              onClick={()=>toggleMenu()}
            />
            <NavLink to="/" className={style.title} onClick={()=> eventEmit("Volver al Dashboard", path)}>
              <Text 
                tagName="h1" 
                headingSize="m" 
                >
                  <span>Im</span>
                  <span>pulse</span>
              </Text>
            </NavLink>
            
          </div>

          <div className={style.right}>
            <Info />
          </div>

          {/* Select flow modal */}
          <Modal
            visible={modalSelectFlowOpen}
            focusLockProps={{
              disabled: false
            }}
            handleCloseBtn={()=> setModalSelectFlowOpen(!modalSelectFlowOpen)}
            >
            <Sheets
              aria-label="Accessibility header for a modal"
              header={<ModalHeader className={style.modal_header} title={t("header.selectFlow.title")}/>}
            >
              <ModalBody>
                <Text 
                tagName="p" 
                bodySize="m">
                  {t("header.selectFlow.description")}
                </Text>
                <hr/>
                {flows
                  .filter(flow => flow.active === 1)
                  .map(flow => (
                    <NavLink
                      className="link link--subtle"
                      to="new-action"
                      onClick={() => updateFlow(flow)}
                      key={flow.id}
                    >
                      <Text tagName="p" bodySize="l">
                        {flow.name}
                      </Text>
                    </NavLink>
                  ))}
              </ModalBody>
            </Sheets>
          </Modal>

          {/* Side menu */}
          <aside className={`${style.side_menu} ${clicked ? style.open : ''}`}>
            <div className={style.side_menu_header}>
            <Text 
              tagName="h1" 
              headingSize="m" 
              className={style.title}>
                <span>Im</span>
                <span>pulse</span>
            </Text>
              <Button 
                type="tertiary" 
                iconOnly 
                size="small" 
                ssrIcon={cross} 
                onClick={()=>toggleMenu()}
              />
            </div>
            <div className={style.side_menu_body}>
              <MainMenu changeView={()=>toggleMenu()}/>
            </div>
            <div className={style.side_menu_footer}>
              <SelectIngka
                select={{
                  onChange: changeLanguage,
                  id: "langSelector",
                  value: lang
                }}
                options={languages}
              />
              <Button 
                type="secondary"
                className={style.logout}
                text={t("header.logoutButton")}
                size="small"
                iconPosition="trailing"
                ssrIcon={loguoutIcon} 
                onClick={logout}
              />
            </div>
          </aside>
          <div className={`${style.overlay} ${clicked ? style.open : ''}`}></div>
        </div>
      </div>
    </>
  )
}
