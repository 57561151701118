/* eslint-disable react-hooks/exhaustive-deps */
import { Line, LineChart, ReferenceLine } from "recharts";
import style from "../../assets/scss/components/global/chartList.module.scss"
import Text from '../content/Text';
import Check from "../form/Check";
import { useEffect, useState } from "react";

export default function ChartList({data, updateSelectedItem}) {
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    if(selectedItem) updateSelectedItem(selectedItem)
  }, [selectedItem])

  return (
    <section className="w-100">
      {data.map( item => 
        <div className={style.container} key={item.id}>
          <div>
            <Text tagName="p" bodySize="m">{item.name}</Text>
            <Text tagName="p" bodySize="m" >
              <strong style={{color: item.color}}>{item.value}</strong>
              </Text>
          </div>
          <div className={style.chart}>
            <LineChart
              className={style.lineChart}
              width={120}
              height={60}
              margin={{
                top: 2,
                right: 10,
                left: 10,
                bottom: 2,
              }}
              >
                <ReferenceLine y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} strokeDasharray="5 5"/>
                <Line strokeWidth={2} data={item.chart} dot={false} type="monotone" dataKey="y" stroke="rgb(0, 124, 193)" tooltipType="none" />
            </LineChart>
            <Check
              check={{
                onChange:(e) => {
                  setSelectedItem(item)
                },
                checked: selectedItem?.id === item.id
              }}
            />
          </div>
        </div>
      )}
    </section>
    
  )
}
