/* eslint-disable react-hooks/exhaustive-deps */
import Message from "../components/content/Message";
import { useTranslation } from "react-i18next";
import arrowR from '@ingka/ssr-icon/paths/arrow-right'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Load from '../components/global/Load';
import Button from "../components/content/Button";
import { createMsgToast, updateActualDate, updateFilterHfb, updateFilterMonth, updateFilterPa, updateFlowSelected, updateLastDateData } from "../store/globalSlice";
import Table from "../components/content/Table";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import { getFlows, getMainData, updateFlowState } from "../services/flowServices";
import MyTab from '../components/content/MyTab';
import { COWORKER, SUPERADMIN } from "../utils/roles";
import Switch from "../components/form/Switch";
import Text from "../components/content/Text";
import { setFlows } from "../store/flowsSlice";
import { getDateAvailabilityFlow7 } from "../services/mainServices";


export default function Dashboard() {
  const [t] = useTranslation("global")
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [modalActive, setModalActive] = useState(false)
  const [flowForActivate, setFlowForActivate] = useState()
  const [firstLoad, setFirstLoad] = useState(true)
  const flows = useSelector((state) => state.flows)
  const store = useSelector((state) => state.global.filtersSelected.store.id)
  const period = useSelector((state) => state.global.filtersSelected.period?.id)
  const hfb = useSelector((state) => state.global.filtersSelected?.hfb?.id)
  const rol = useSelector((state) => state.global.rol);
  const department = useSelector((state) => state.global.filtersSelected.department)
  const categoryProductivity = useSelector((state) => state.global.filtersSelected.categoryProductivity)
  const columns = [
    {
      accessorKey: "label", 
      header: "",
      size: 140,
      muiTableBodyCellProps: {
        sx:{
          maxWidth: 190,
          textWrap: "wrap"
        }
      },
    },
    {
      accessorKey: "ctry", 
      header: "CTRY",
      size: 60,
      Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>,
    },
    {
      accessorKey: "sto",
      header: "STO",
      size: 60,
      Cell: ({cell, row}) => <span style={{color: cell.column.id === 'sto' ? row.original.sto_color : '#000'}}>
        <b>{cell.getValue()}</b>
      </span>,
    }
  ]

  useEffect(()=>{
    //clear default filter
    dispatch(updateFilterHfb(null))
    dispatch(updateFilterPa(null))
    dispatch(updateActualDate())
  },[])

  const goToGetMainData = (flows, date) => {
    const mainData = [
      {title: t('dashboard.commercial'), body: []},
      {title: t('dashboard.customer'), body: []},
      ...(rol !== COWORKER ? [{title: t('dashboard.productivity'), body: []}] : [])
    ]
    setLoading(true)
      getMainData(
        {
          store:store, 
          hfb:hfb && !firstLoad ? hfb : '', 
          period: period,
          department: department,
          productivityCategory: categoryProductivity,
          month: date.month,
          year: date.year
        }).then( responseData => {
        if(responseData.length > 0){
          responseData.forEach( el => {
            el.flow = flows.find(flow => flow.id === el.id)
            if(el.id === 1 || el.id === 2 || el.id === 3 ||el.id === 4){
              mainData[0].body.push(el)
            }
            if(el.id === 5){
              mainData[1].body.push(el)
            }
            if(el.id === 7 && rol !== COWORKER){
              mainData[2].body.push(el)
            } 
          })
          setData(mainData)
        }else{
          setData([])
          dispatch(createMsgToast({body: t('global.noData'), title:"Error", variant:"negative"}))
        }
      }).catch(() => {
        setData([])
        dispatch(createMsgToast({body: t('global.noData'), title:"Error", variant:"negative"}))
      }).finally(() => {
        setLoading(false)
        setFirstLoad(false)
      })
  }

  const goToGetFlows = () => {
    getFlows().then( flows => {
      let flowsData = flows.map((flow) => 
        ({...flow, slug: flow.name.toLowerCase().replaceAll(' / ',' ').replaceAll(' ','-')}))
      
      //delete flow7 Productivity for Coworker rol
      if(rol === COWORKER){
        flowsData = flowsData.filter( flow => flow.id !== 7)
      }else{
        getDateAvailabilityFlow7().then( date => {
          dispatch(updateLastDateData(date))
          dispatch(updateFilterMonth(date.month))
          goToGetMainData(flows, date)
        })
      }
      dispatch(setFlows(flowsData))
      
    })
  }

  useEffect(()=>{
    if(store && period){
      goToGetFlows()
    }
  },[hfb, store, period])

  const goToFlow = (flow)=>{
    const flowSelected = flows.find(el => el.id === flow.id)
    dispatch(updateFlowSelected(flowSelected))
    navigate(`/flow/${flowSelected.slug}`, flow.data)
  }

  const openModal = (flow)=>{
    setModalActive(!modalActive)
    setFlowForActivate(flow)
  }

  const updateFlowActive = ()=>{
    const data = {
      flowid: flowForActivate.id,
      active: flowForActivate.flow.active === 1 ? 0 : 1
    }
    setModalActive(!modalActive)
    updateFlowState(data, t('dashboard.changeStatusFlow')).then((response) => {
      goToGetFlows()
    })
  }
  
  return (
    <>
    {loading && <Load />}
    {!loading && data.length > 0 &&
      <MyTab
      defaultTab={data[0].name}
      tabs={
        data?.map( tab =>(
          {text:tab.title, tabPanelId:tab.title}
        ))
      }
      tabPanels= {
        data.map(panel =>(
          {
            tabPanelId:panel.title,
            children:
            panel.body.length > 0 &&
            panel.body.map(flow => 
              <Message
              key={flow.id}
              title={
              <div>
                <Text tagName="p" bodySize="m">{flow.name}</Text>
                {flow.flow.active === 1 && flow.data.length > 0 &&
                  <Table columns={columns} data={flow.data} onClick={()=>goToFlow(flow)}/>
                }
                {flow.flow.active === 1 && flow.data.length === 0 &&
                  <Text tagName="p" bodySize="s" className="text-center text-red">{t('global.noDataForFlow')}</Text>
                }
                {rol === SUPERADMIN && 
                  <Switch props={{
                    className: "mt-1",
                    id: flow.id,
                    name: flow.name,
                    label: flow.flow.active === 1 ? t("global.activated") : t("global.disabled"),
                    checked: flow.flow.active,
                    onChange: ()=> openModal(flow)
                  }}/>
                }
                {rol !== SUPERADMIN && flow.flow.active === 0 &&
                  <small>{flow.flow.inactivemessage}</small>
                }
                
              </div>}
              actions={ flow.flow.active === 1 ? [
                {
                  onClick: ()=>goToFlow(flow),
                  iconOnly: true,
                  ssrIcon: arrowR,
                  className:"goToButton"
                },
              ] : []}
              variant={flow.flow.active === 0 ? "negative" : "informative"}
            />
            )

          }
        ))
        }
    />
    }
    {!loading && data.length === 0 &&
      <Text tagName="p" bodySize="m" className="text-center text-red">{t('global.noData')}</Text>
    }  
    <Modal
    visible={modalActive}
    handleCloseBtn={()=> setModalActive(!modalActive)}>
      <Prompt
        aria-label="Accessibility header for a modal"
        className="example-prompt-override"
        footer={<ModalFooter>
          <Button text={flowForActivate?.flow.active === 0 ? t("global.activate") : t("global.deactivate")} type="emphasised" onClick={updateFlowActive}/>
          <Button text={t("global.cancel")} /></ModalFooter>}
        header={<ModalHeader ariaCloseTxt="Close button text" />}
        title={flowForActivate?.flow.active === 0 ? t("global.activate") : t("global.deactivate") + " " + flowForActivate?.name}
      >
      </Prompt>
    </Modal>
    </>
  )
}
