import { MaterialReactTable } from 'material-react-table'
import React from 'react'

export default function Table({columns, data, short, colPing}) {
  return (
    <MaterialReactTable
      columns={columns} 
      data={data}
      enableColumnActions={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      enablePagination={false}
      muiTableBodyRowProps={{ hover: false }}
      enablePinning
      initialState={{
        density: 'compact',
        sorting: short === undefined ? [] : [{...short}],
        columnPinning: { left: [colPing]}
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: "none"
        },
      }}

      muiTableBodyProps={{
        sx: {
          '& tr td': {
            borderBottom: "none",
            padding: "0.2rem"
          },
        }
      }}

      muiTableHeadCellProps={{
        sx: {
          borderBottom: "2px solid rgb(223, 223, 223)",
        },
      }}
      muiTableBodyCellProps={({ row, cell }) => ({
          style: row.original.selected ? {backgroundColor: 'rgb(254 234 226)'}:{}
        })
      }
      
      />
  )
}
