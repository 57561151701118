import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stores: JSON.parse(localStorage.getItem("stores")) || [],
  hfbs: JSON.parse(localStorage.getItem("hfbs")) || [],
  periods: JSON.parse(localStorage.getItem("periods")) || [],
  pas: JSON.parse(localStorage.getItem("pas")) || [],
  departments: JSON.parse(localStorage.getItem("departments")) || [],
  categoryProductivity: JSON.parse(localStorage.getItem("categoryProductivity")) || []
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setStores: (state, action)=> {
      state.stores = action.payload
      localStorage.setItem("stores", JSON.stringify(state.stores))
    },
    setHfbs: (state, action)=> {
      state.hfbs = action.payload
      localStorage.setItem("hfbs", JSON.stringify(state.hfbs))
    },
    setPeriods: (state, action)=> {
      state.periods = action.payload
      localStorage.setItem("periods", JSON.stringify(state.periods))
    },
    setPas: (state, action)=> {
      state.pas = action.payload
      localStorage.setItem("pas", JSON.stringify(state.pas))
    },
    setDepartments: (state, action)=> {
      state.departments = action.payload
      localStorage.setItem("departments", JSON.stringify(state.departments))
    },
    setCategoryProductivity: (state, action)=> {
      state.categoryProductivity = action.payload
      localStorage.setItem("categoryProductivity", JSON.stringify(state.categoryProductivity))
    }
  },
});

export const { 
  setStores, 
  setHfbs, 
  setPeriods, 
  setPas, 
  setDepartments,
  setCategoryProductivity
} = filtersSlice.actions;
export default filtersSlice.reducer;
