/* eslint-disable react-hooks/exhaustive-deps */
import Status from "../content/Status";
import Switch from "../form/Switch";
import Text from "../content/Text";
import Badge from "../content/Badge";
import style from "../../assets/scss/components/global/actionCard.module.scss"
import { useTranslation } from "react-i18next";

import "@ingka/svg-icon/style.scss";
import "@ingka/accordion/style.scss";
import "@ingka/focus/style.scss";

import { AccordionItem } from "@ingka/accordion";
import { useEffect, useState } from "react";

export default function ActionCard({data, openModal, openImage}) {
  const [t] = useTranslation("global")
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  useEffect(() => {
    if(data.temporality === "weekly"){
      setStart(data.wkstartdate.slice(0,4) + ".W" + data.wkstartdate.slice(4))
      setEnd(data.wkenddate.slice(0,4) + ".W" + data.wkenddate.slice(4))
    } 
    if(data.temporality === "monthly"){
      setStart(data.mthStartDate.toString().slice(0,4) + "-" + data.mthStartDate.toString().slice(4))
      setEnd(data.mthEndDate.toString().slice(0,4) + "-" + data.mthEndDate.toString().slice(4))
    }
  }, [])
  
  return (
    <AccordionItem
    id={data.idTask}
    caption={
      <>
        <div className={style.header}>
          <div>
            <Text tagName="h2" headingSize="m" className={style.title}>
              {data.flowid !== 7 ? data.iteminfo.name : data.variable}
            </Text>
            <Text tagName="p" bodySize="m" className="m0"><b>
              {data.flowid !== 7 ? data.iteminfo.description : data.categoryProductivity
              }</b>
            </Text>
            <Text tagName="p" bodySize="m" className={style.flow}>{t("actions.flow")}: {data.flowname}</Text>
          </div>
          <div className={style.info}>
            <Badge background={data.achivedpotencialcolor} value={`${data.achivedpotencial}%`}/>
            <Status 
            label={data.status_name} 
            statusColor={data.status_id === 0 ? "grey" : (data.status_id === 1 ? "green" : "red")} 
            small
            statusDotPosition="trailing"
            />
          </div>
        </div>
      </>
    }
    >
    <div>
      <div className={style.body}>
        <Text tagName="p" bodySize="m">{t("actions.period")}: {start} / {end}</Text>
        <Text tagName="p" bodySize="m">{t("actions.responsible")}: {data.actionresponsible}</Text>
        <Text tagName="p" bodySize="m">{t("actions.contributor")}: {data.actioncontributor}</Text>
        <Text tagName="p" bodySize="m">{t("actions.description")}: {data.actiondescription}</Text>
      </div>
      <div className={style.footer}>
        <Switch props={{
          id: data.idTask,
          name: data.idTask,
          label: data.completed ? t("actions.complete") : t("actions.pendingComplete"),
          disabled: false,
          checked: data.completed,
          onChange: openModal
        }}/>
      </div>
      
      <div className={style.image_container}>
        {data.files?.map((file, index) => (
          <div 
          className={style.box} 
          key={index} 
          style={{background: `url(${file})`}}
          onClick={(e)=> openImage(e.target.style.backgroundImage)}
          ></div>
        ))}
        
      </div>
    </div>
    </AccordionItem>
  )
}
