/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../content/Button'
import Modal, { Sheets, ModalHeader, ModalBody, ModalFooter } from "@ingka/modal";
import image from '@ingka/ssr-icon/paths/image'
import cross from '@ingka/ssr-icon/paths/cross-circle'
import camera from '@ingka/ssr-icon/paths/camera'
import upload from "@ingka/ssr-icon/paths/arrow-up-from-base"
import { useTranslation } from 'react-i18next';
import style from "../../assets/scss/components/global/uploadFile.module.scss"
import Webcam from "react-webcam";
import Load from '../global/Load';

export default function UploadFile({handleFiles}) {
  const [t] = useTranslation("global")
  const[modalSelectUploadMode, setModalSelectUploadMode] = useState(false)
  const[modalTakePicture, setModalTakePicture] = useState(false)
  const[files, setFiles] = useState([])
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const toggleModalUploadMode = ()=>{
    setModalSelectUploadMode(!modalSelectUploadMode)
  }
  const toggleModalTakePicture = ()=>{
    setModalTakePicture(!modalTakePicture)
  }

  const deleteFile = (indexDelete)=>{
    const duplicateFiles = [...files]
    duplicateFiles.splice(indexDelete, 1)
    setFiles(duplicateFiles)
  }

  const openTakePicture = ()=>{
    toggleModalUploadMode()
    toggleModalTakePicture()
  }

  const uploadFile = ()=>{
    toggleModalUploadMode()
    let el = window._protected_reference = document.createElement("INPUT");
    el.type = "file";
    el.accept = "image/*";
    el.addEventListener('change', function() {
      if (el.files.length) {
        const reader = new FileReader();
        const file = el.files[0];
        reader.onload = function(upload) {
          setFiles([...files,upload.target.result])
        }
        reader.readAsDataURL(file)
      }
      el = window._protected_reference = undefined;
    });
    el.click();
    
  }


  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const savePicture = ()=>{
    setFiles([...files,imgSrc])
    toggleModalTakePicture()
    setImgSrc(null)
    
  }

  useEffect(()=>{
    handleFiles(files)
  },[files])

  return (
    <>
      <div className={style.upload}>
        {files?.map((file, index) => (
          <div className={style.box} key={index} style={{background: `url(${file})`}}>
            <Button
              type="tertiary" 
              iconOnly 
              ssrIcon={cross} 
              onClick={()=>deleteFile(index)}
              className={style.deleteFile}
              />
          </div>
        ))}

        <Button
          type="tertiary" 
          iconOnly 
          ssrIcon={image} 
          onClick={toggleModalUploadMode}
          className={style.addButton}
        />
      </div>

      {/* Selector tipe of capture picture modal */}
      <Modal
        visible={modalSelectUploadMode}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={()=> setModalSelectUploadMode(!modalSelectUploadMode)}
        >
        <Sheets
          aria-label="Accessibility header for a modal"
          header={<ModalHeader className="modalHeader" title={t("components.uploadFile.title")}/>}
          footer={<ModalFooter />}
        >
          <ModalBody className={style.modalBody}>
          <Button
            type="secondary" 
            text={t("components.uploadFile.takePicture")}
            ssrIcon={camera} 
            onClick={openTakePicture}
            className={style.modeUploadButton}
            />
            <Button
            type="secondary" 
            text={t("components.uploadFile.uploadFile")}
            ssrIcon={upload} 
            onClick={uploadFile}
            />
          </ModalBody>
        </Sheets>
      </Modal>

      {/* Webcam for take picture modal */}
      <Modal
        visible={modalTakePicture}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={()=> {
          setModalTakePicture(!modalTakePicture)
          setImgSrc(null)
        }}
        >
        <Sheets
          aria-label="Accessibility header for a modal"
          header={<ModalHeader className="modalHeader"/>}
          footer={<ModalFooter />}
        >
          <ModalBody className={style.modalBody}>
            {!imgSrc &&
              <>
                <Webcam
                  height="auto"
                  width="100%"
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  />
                  <div className={style.loadingBack}>
                    <Load/>
                  </div>
                
                <Button
                  type="emphasised"
                  className="mt-2"
                  iconOnly
                  ssrIcon={camera}
                  onClick={capture}/>
              </>
            }
            
            {imgSrc && 
              <>
                <img
                  alt="Capture"
                  src={imgSrc}
                />
                <Button
                  type="secondary"
                  className="mt-2"
                  text="Borrar"
                  size="small"
                  onClick={()=>setImgSrc(null)}/>
                <Button
                  type="emphasised"
                  className="mt-2 ml-1"
                  text="Aceptar"
                  size="small"
                  onClick={savePicture}/>
              </>
            }
          </ModalBody>
        </Sheets>
      </Modal>
    </>
  )
}
