import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import style from '../../assets/scss/components/form/select.module.scss'
import { useTranslation } from 'react-i18next';

export default function Sel({
  formField, 
  label, 
  onChange, 
  value, 
  ref, 
  name, 
  options, 
  isClearable = false, 
  isSearchable = false, 
  isDisabled = false,
  defaultValue}) {
  const animatedComponents = makeAnimated();
  const [t] = useTranslation("global")

  return (
    <div className={`${formField.className} label-wrapper form-field`}>
      <label >{label}</label>
      <Select
        placeholder={t('components.select.selectMultiple')}
        className={style.select}
        closeMenuOnSelect={true}
        components={animatedComponents}
        isClearable={isClearable}
        isSearchable={isSearchable}
        ref={ref}
        onChange={onChange}
        value={value}
        name={name}
        defaultValue={defaultValue}
        options={options}
        isDisabled={isDisabled}
      />
      {formField.shouldValidate &&
        <div className="form-field__content">
        <span id={formField.validation.id} className={`helper-text helper-text--${formField.validation.type}`}>
          {formField.validation.msg}
        </span>
      </div>
      }
      
    </div>
    
  )
}
