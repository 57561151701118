import IngkaStatus from "@ingka/status";
import "@ingka/status/style.scss";

export default function Status(props) {
  return (
    <IngkaStatus
    {...props}
    small
  />
  )
}
