/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Dashboard from '../views/Dashboard';
import Header from '../components/global/Header';
import Actions from '../views/Actions';
import Flow from '../views/Flow';
import Filter from "../components/global/Filter";
import Text from "../components/content/Text";
import { useTranslation } from "react-i18next";
import NewAction from '../views/NewAction';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { updatePage, updatePath } from '../store/globalSlice';



export default function PrivateRouter() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const [pathTitle, setPathTitle] = useState()
  const flowSelected = useSelector((state) => state.global.flowSelected)
  const RoutesMaster = [
    {
        path: "dashboard",
        Element: Dashboard
    },
    {
        path: "actions",
        Element: Actions
    },
    {
        path: "flow/:theFlow",
        Element: Flow
    },
    {
        path: "new-action",
        Element: NewAction
    },
  ]
  
  let location =  useLocation();
  let paths = location.pathname.split('/')
  let path = null;

  //set last path for titles 
  if(paths.length > 1){
    path = paths[paths.length -1]
  }
  //location change
  useEffect(() => {
    dispatch(updatePath(path))
    
    if(paths[1] === "flow"){
      setPathTitle("flow"+flowSelected.id)
      dispatch(updatePage("flow"+flowSelected.id))
    }else{
      setPathTitle(path)
      dispatch(updatePage(path))
    }
  }, [location])

  return (
    <>
      <header>
        <Header />
      </header>
      <div className="container">
        <Text tagName="h2" headingSize="l">{t(`${pathTitle}.title`)}</Text>
        <Text tagName="p" bodySize="m">{t(`${pathTitle}.subtitle`)}</Text>
        <Filter />
        <Routes>
          <Route path='/' element={<Navigate to="dashboard" />}/>
          {
            RoutesMaster.map(({path, Element}, index) => (
              <Route path={path} element={<Element />} key={index}/>
            ))
          }
        </Routes>
      </div>
    </>
  )
}
