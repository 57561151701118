export default function RequiredField({text}) {
  return (
    <span>
      {text}
      <span className='text-red'>
        *
      </span>
    </span>
  )
}
