import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/focus/style.scss';
import Checkbox from '@ingka/checkbox';
import FormField from '@ingka/form-field';

export default function Check({formField, check}) {
	return (
		<FormField
			{...formField}
		>
			<Checkbox
				{...check}
			/>
		</FormField>
	)
}
