export const calculateMonthOptions = (lastMonth) => {
  const months = [9,10,11,12,1,2,3,4,5,6,7,8] //months in a fiscal year
  const startIndex = months.indexOf(lastMonth);
  const stopValue = 9; //september
  let options = []
  for (let i = startIndex; i >= 0; i--) {
    options.push(months[i]);
    if (months[i] === stopValue) {
      break;
    }
  }
  return options
}