import { apiFetch } from '../configuration/api/apiFetch';
import { API_URLS } from '../configuration/api/apiConstants';
import { store } from '../store/store';
import { createMsgToast } from '../store/globalSlice';

export const getFlows = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.FLOWS,
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}

export const getMainData = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.MAIN,
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}

export const updateFlowState = (data, successMsg) => {
  const fetchSettings = {
    method: "PUT",
    url: API_URLS.FLOWS,
    data
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      store.dispatch(createMsgToast({ title: successMsg, variant:"positive"}))
      resolve(response)
    })
  })
  return dataFetch
}

export const getFlow = (id,params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.FLOW(id),
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}