/* eslint-disable react-hooks/exhaustive-deps */
import Table from "../components/content/Table";
import MyTab from "../components/content/MyTab";
import Message from "../components/content/Message";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateFilterHfb, updateFilterPa } from "../store/globalSlice";
import Load from "../components/global/Load";
import { RegularizeDataTable, ColumnsHeadTable } from "../utils/RegularizeDataTable";
import { useNavigate } from "react-router-dom";
import { getFlow } from "../services/flowServices";
import Text from "../components/content/Text";
import { useTranslation } from "react-i18next";


export default function Flow() {
    const dispatch = useDispatch();
    const flowSelected = useSelector((state) => state.global.flowSelected)
    const defaultHfb = useSelector((state) => state.filters.hfbs[0])
    const store = useSelector((state) => state.global.filtersSelected.store)
    const period = useSelector((state) => state.global.filtersSelected?.period.id)
    const hfb = useSelector((state) => state.global.filtersSelected?.hfb)
    const pa = useSelector((state) => state.global.filtersSelected.pa)
    const defaultPa =  useSelector((state) => state.filters.pas[0])
    const monthSelected = useSelector((state) => state.global.filtersSelected?.month)
    const year = useSelector((state) => state.global.year);
    const department = useSelector((state) => state.global.filtersSelected.department)
    const categoryProductivity = useSelector((state) => state.global.filtersSelected.categoryProductivity)
    const [loading, setLoading]=useState(false)
    const [dataTable, setDataTable]=useState([])
    const [dataHead, setDataHead] = useState([])
    const [columnsHead, setColumnsHead] = useState([])
    const navigate = useNavigate();
    const [t] = useTranslation("global")

    useEffect(()=>{
      //check if flow is deactivated and is null
      if(flowSelected === null){
        navigate('/dashboard')
      }
      //add default filter hfb 01
      if(hfb === undefined || hfb === null) dispatch(updateFilterHfb(defaultHfb))
      //add default filter pa
      if(pa === null)dispatch(updateFilterPa(defaultPa))
    },[])

    useEffect(()=>{
      setLoading(true)
      if(flowSelected && store && hfb && period !== null && department && categoryProductivity){
        const params = {
          store:store.id, 
          hfb:hfb.id, 
          period: period,
          ...(flowSelected.id !== 1  ? {pa: pa ? pa.id : defaultPa.id} : {}),
          department: department,
          productivityCategory: categoryProductivity,
          month: monthSelected,
          year: year
        }
        getFlow(flowSelected.id, params).then ( flow => {
          setColumnsHead(ColumnsHeadTable(flowSelected))
          setDataTable(RegularizeDataTable(flow, flowSelected, hfb, store, pa ? pa : defaultPa.id, categoryProductivity))
          if(flowSelected.id !== 1){
            setDataHead(flow.head)
          }
        }).finally(() => setLoading(false))
      }
    },[flowSelected,store,hfb,period,pa,categoryProductivity, department, monthSelected])

  return (
    <>
    {loading &&
      <Load />
    }
    {!loading &&
    <>
      {flowSelected?.id !== 1 && dataHead.length > 0 &&
        <Message
          title={<>
            <Table 
                columns={columnsHead} 
                data={dataHead}
              />
          </>}
        />
      }
      {dataTable.length > 0 &&
        <MyTab
          tabs={
            dataTable?.map((tab)=>(
              {text:tab.label, tabPanelId:tab.label}
            ))
          }
          tabPanels= {
            dataTable?.map((tab)=>(
              {
                tabPanelId:tab.label,
                children: 
                <div className="table table-flow">
                  <Table 
                    columns={tab.columns} 
                    data={tab.data}
                    short={tab.short}
                    colPing={tab.colPing}
                    />
                </div>
              }
            ))
            }
        />
      }
      {dataTable.length === 0 &&
        <Text tagName="p" bodySize="m" className="text-center text-red">{t('global.noDataForFlow')}</Text>
      }
        
      </>
    }
    </>
  )
}
