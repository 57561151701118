import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "./globalSlice";
import flowsReducer from "./flowsSlice";
import filtersReducer from "./filtersSlice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    flows: flowsReducer,
    filters: filtersReducer
  },
});