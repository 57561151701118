import { apiFetch } from '../configuration/api/apiFetch';
import { API_URLS } from '../configuration/api/apiConstants';

export const getActionsRegister = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.ACTIONS_REGISTER,
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}

export const updateAction = (data) => {
  const fetchSettings = {
    method: "PUT",
    url: API_URLS.ACTIONS_REGISTER,
    data
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response)
    })
  })
  return dataFetch
}