import React, { useState } from 'react'
import Button from '../content/Button'
import infoIcon from '@ingka/ssr-icon/paths/information-circle'
import style from '../../assets/scss/components/global/info.module.scss'
import Modal, { Sheets, ModalHeader, ModalBody } from "@ingka/modal";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { getInfo } from '../../services/mainServices';

export default function Info() {
  const [t] = useTranslation("global")
  const[modalInfo, setModalInfo] = useState(false)
  const page = useSelector((state) => state.global.page)
  const[text, setText] = useState("")

  const openInfoModal = ()=>{
    setModalInfo(!modalInfo)
    goToGetInfo()
  }

  const goToGetInfo = ()=>{
    getInfo({screen: page}).then( response => {
      setText(response[0].html)
    })
  }

  return (
    <div className={style.info}>
      <Button 
        type="tertiary" 
        iconOnly 
        size="small"
        ssrIcon={infoIcon} 
        className={style.button}
        onClick={openInfoModal}
      />

      <Modal
        visible={modalInfo}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={()=> setModalInfo(!modalInfo)}
        >
        <Sheets
          aria-label="Accessibility header for a modal"
          header={<ModalHeader className={style.modal_header} title={t("info.title")}/>}
        >
          <ModalBody>
            <div className={style.text}>
              {parse(text)}
            </div>
          </ModalBody>
        </Sheets>
      </Modal>
    </div>
  )
}
