import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem("flows")) || []

export const flowsSlice = createSlice({
  name: "flows",
  initialState,
  reducers: {
    setFlows: (state, action)=> {
      state = action.payload
      localStorage.setItem("flows", JSON.stringify(state))
      return state
    }
  },
});

export const { setFlows } = flowsSlice.actions;
export default flowsSlice.reducer;