/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import ActionCard from "../components/global/ActionCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Load from "../components/global/Load";
import { updateActualDate } from "../store/globalSlice";
import Modal, {ModalFooter, ModalHeader, Prompt, Theatre} from "@ingka/modal";
import Button from "../components/content/Button";
import "@ingka/svg-icon/style.scss";
import "@ingka/accordion/style.scss";
import "@ingka/focus/style.scss";

import Accordion from "@ingka/accordion";
import { getActionsRegister, updateAction } from "../services/actionServices";

export default function Actions() {
  const [t] = useTranslation("global")
  const [actions, setActions] = useState([])
  const user = useSelector((state) => state.global.user)
  const store = useSelector((state) => state.global.filtersSelected.store)
  const hfb = useSelector((state) => state.global.filtersSelected.hfb)
  const pa = useSelector((state) => state.global.filtersSelected.pa)
  const flow = useSelector((state) => state.global.filtersSelected.flow)
  const myActions = useSelector((state) => state.global.filtersSelected.myActions)
  const state = useSelector((state) => state.global.filtersSelected.state)
  const [loading, setLoading] = useState(true)
  const [modalComplete, setModalComplete] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0);
  const [idTaskUpdate, setIdTaskUpdate] = useState()
  const [statusCheckedAction, setStatusCheckedAction] = useState()
  const [modalImage, setModalImage] = useState(false)
  const [image, setImage] = useState('')
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(updateActualDate())
  },[])

  useEffect(()=>{
    setLoading(true)
    const params = {
      hfb: hfb !== null ? hfb.id : '',
      store: myActions ? '' : store.id,
      pa: pa !== null ? pa.name : '',
      userid: user,
      flow: flow.id,
      myActions: myActions,
      state: state
    }
    getActionsRegister(params).then( actions => {
      setActions(actions)
    }).finally(() => setLoading(false))
  },[user,pa,store,hfb,refreshKey,flow,state,myActions])

  const openModal = (e)=>{
    setModalComplete(!modalComplete)
    setIdTaskUpdate(e.target.id)
    setStatusCheckedAction(e.target.checked)
  }

  const updateCompleted = ()=>{
    const data = {
      idtask: idTaskUpdate,
      completed: statusCheckedAction ? 1 : 0
    }
    updateAction(data).then( () => {
      setRefreshKey(oldKey => oldKey +1)
      setModalComplete(!modalComplete)
    })
  }

  const openImage = (img)=>{
    setModalImage(!modalImage)
    setImage(img)
  }

  return (
    <>
      {loading && 
        <Load />
      }
      {!loading &&
        <Accordion>
          {actions?.map(action => 
          <ActionCard key={action.idTask} data={action} user={user} openModal={openModal} openImage={openImage}/>
        )}
        </Accordion>
        
      }
      {/* Modal complete action */}
      <Modal 
        visible={modalComplete}
        handleCloseBtn={()=> setModalComplete(!modalComplete)}>
          <Prompt
            aria-label="Accessibility header for a modal"
            className="example-prompt-override"
            footer={<ModalFooter>
              <Button 
                text={statusCheckedAction ? t("actions.modalCompleteOk") : t("actions.modalCompleteKo")} 
                type={statusCheckedAction ? "emphasised" : "danger"}
                onClick={updateCompleted}/>
              <Button text={t("global.cancel")} /></ModalFooter>}
            header={<ModalHeader ariaCloseTxt="Close button text" />}
            title={statusCheckedAction ? t("actions.modalCompleteTitle") : t("actions.modalNoCompleteTitle")}
          >
          </Prompt>
        </Modal>

        {/* Modal open Image */}
        <Modal 
        visible={modalImage}
        handleCloseBtn={()=> setModalImage(!modalImage)}>
          <Theatre
          header={<ModalHeader ariaCloseTxt="Close button text"/>}
          >
            <div style={{
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundImage: image,
              width: "100%",
              height: "100%",
            }}></div>
          </Theatre>
        </Modal>
    </>
  )
}
