import { useTranslation } from "react-i18next";
import Text from '../content/Text';
import { Link, NavLink } from 'react-router-dom';
import style from "../../assets/scss/components/global/menu.module.scss"
import { useDispatch, useSelector } from "react-redux";
import { updateFlowSelected } from "../../store/globalSlice";


export default function MainMenu({changeView}) {
  const [t] = useTranslation("global")
  const flows = useSelector((state) => state.flows);
  const dispatch = useDispatch();

  const goToFlow = (flow)=>{
    dispatch(updateFlowSelected(flow))
    changeView()
  }


  return (
    <nav>
      <Text tagName="p" bodySize="xl" >
        <b>{t("header.menu.flows")}</b>
      </Text>
      <div className={style.submenu}>
      {flows.map(flow => (
        flow.active === 1 && (
          <Link
            className="link link--subtle"
            to={`/flow/${flow.slug}`}
            onClick={() => goToFlow(flow)}
            key={flow.id}
          >
            <Text tagName="p" bodySize="l">
              {flow.name}
            </Text>
          </Link>
        )
      ))}
      </div>

      <NavLink className="link link--subtle" to="actions" onClick={changeView}>
        <Text tagName="p" bodySize="l" >
          <b>{t("header.menu.yourActions")}</b>
        </Text>
      </NavLink>
      <NavLink className="link link--subtle" 
        to="https://app.powerbi.com/groups/de4adbc4-d8ac-41bb-917e-5e0eafe1447d/reports/800409ee-bcb2-4c94-8840-be3cbcc564cb/ReportSection?experience=power-bi" 
        onClick={changeView}>
        <Text tagName="p" bodySize="l" >
          <b>{t("header.menu.news")}</b>
        </Text>
      </NavLink>
    </nav>
  )
}
