/* eslint-disable react-hooks/exhaustive-deps */
import Message from "../../components/content/Message";
import { useTranslation } from "react-i18next";
import Text from "../../components/content/Text";
import style from "../../assets/scss/view/newAction.module.scss"
import React, { useEffect, useRef, useState } from "react";
import ArticleCard from "../../components/global/ArticleCard";
import TextArea from "../../components/form/TextArea";
import Button from "../../components/content/Button";
import UploadFile from "../../components/global/UploadFile";
import Load from "../../components/global/Load";
import Table from "../../components/content/Table";
import { OrderByPosition } from "../../utils/OrderByPosition";
import Select from "../../components/form/Select";
import { Controller, useForm,} from "react-hook-form";
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line'
import { getActions, getFlowDetails, getFlowsHead } from "../../services/newActionServices";
import { RETURNS_ID } from "../../utils/flowsIds";
import RequiredField from "../form/RequiredField";
import useDateOptions from "../../utils/useDateOptions";


export default function GeneralNewAction({flow, store, employees, hfb, user, saveAction, loadingButton}) {
  const [t] = useTranslation("global")
  const [articles, setArticles] = useState([])
  const [actions, setActions] = useState([])
  const [actionSelected, setActionSelected] = useState("")
  const [loading, setLoading] = useState(true)
  const [pas, setPas] = useState([])
  const [columns, setColumns] = useState([])
  const [dataTable, setDataTable] = useState()
  const [pa, setPa] = useState("null")
  const [order, setOrder] = useState({label: "€", value: "E"})
  const [view, setView] = useState({label: "5", value: 5})
  const [loading2, setLoading2] = useState(false)
  const [articlesSelected, setArticlesSelected] = useState([])
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [responsible, setResponsible] = useState()
  const [contributor, setContributor] = useState()
  const [description, setDescription] = useState("")
  const [files, setFiles] = useState([])
  const articlesRef = useRef()
  const formRef = useRef()
  const [buttonSubmitActive, setButtonSubmitActive] = useState(false)
  const {optionsWeekStart, optionsWeekEnd} = useDateOptions()

  const { handleSubmit, control } = useForm();
  
  const orderOptions = [{label: "€", value: "E"}, {label: t('global.units'), value: "U"}]
  const viewOptions = [{label: "5", value: 5}, {label: "10", value: 10}, {label: "15", value: 15}]
  
  useEffect(()=>{
    if(pa !== "null"){
      setPa("null")
      setArticles([])
      setArticlesSelected([])
    } else {
    setLoading(true)
    getFlowsHead({flow: flow.id, store:store.id, hfb:hfb.id}).then( response => {
      const columns = [
        {
          accessorKey: "hfb",
          header: `HFB ${hfb.id}`,
          size: 100,
          Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>,
        },
        {
          accessorKey: "store",
          header: `STO ${store.id}`,
          size: 100,
          Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>,
        }
      ]
      //if flow5 store col first
      if(flow.id === RETURNS_ID) columns.reverse()
      
      setColumns(columns)
      setDataTable([{hfb:response.hfb_potential, store:response.store_potential}])
      response.pa_potentials.forEach((el)=>{
        el.value = el.pa
      })
      response.pa_potentials.push({value:"all", label:t('new-action.all-pa'), position:999})
      setPas(OrderByPosition(response.pa_potentials))
    }).finally(() => setLoading(false))
  }
  },[flow, store, hfb])

  useEffect(()=>{
    getActions({flow: flow.id}).then( actions => {
      setActions(actions.map( act => ({label: act.name, value: act.name})))
    })
  },[flow])

  const changePa = (newValue)=>{
    setArticlesSelected([])
    setPa(newValue)
  }
  const changeOrder = (newValue)=>{
    setArticlesSelected([])
    setOrder(newValue)
  }
  const changeView = (newValue)=>{
    setArticlesSelected([])
    setView(newValue)
  }

  useEffect(()=>{
    if(pa !== "null"){
      setLoading2(true)
      const params = {
        flow: flow.id,
        hfb: hfb.id,
        store: store.id,
        pa: pa.value === "all" ? "" : pa.value,
        orderby: order.value,
        ascending: 0,
        n_acciones: view.value,
        department: "",
        productivityCategory: ""
      }
      getFlowDetails(params).then( details => {
        setArticles(OrderByPosition(details.data))
        setActionSelected('')
        setTimeout(()=>articlesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100)
      }).finally(() => setLoading2(false))
    }
  },[pa,order,view])

  const updateArticleSelected = (e)=>{
    if(articlesSelected.length === 0){
      setArticlesSelected([e.target.value])
    }else{
      const exist = articlesSelected.find((article) => article === e.target.value)
      if(exist !== undefined){
        setArticlesSelected(articlesSelected.filter((article) => article !== e.target.value))
      }else{
        setArticlesSelected([...articlesSelected, e.target.value])
      }
    }
  }

  const handleFiles = (files)=>{
    setFiles(files)
  }

  useEffect(()=>{
    if(actionSelected && articlesSelected && responsible && contributor && start && end !== undefined && description) setButtonSubmitActive(true)
  },[actionSelected,articlesSelected,responsible,contributor,start,end, description])

  const goToSaveAction = ()=>{
    let items = []
    articlesSelected.forEach(el =>{
      const article = articles.find(article => article.item === el)
      items.push(article)
    })

    const data = {
      flowid: flow.id,
      item: items,
      actionname: actionSelected,
      actionresponsible: responsible,
      actioncontributor: contributor,
      actiondescription: description,
      hfbid: hfb.id,
      storeid: store.id,
      statusact: "registro",
      actionregisteruserid: user,
      wkstartdate: start,
      wkenddate: end,
      files: files
    }
    saveAction(data)
  }

  return (
    <>
      {!loading && columns && dataTable &&
        <>
        <Message
          title={
          <>
            <Text
            tagName="p" 
            headingSize="s">
              {flow?.name}
            </Text>
            <Text
            className={style.messageTitle}
            tagName="p" 
            bodySize="m">
              {t("new-action.messageTitle")}
            </Text>
            <Table columns={columns} data={dataTable}/>
          </>}
          
        />
        <hr/>
        <div className={style.container}>
          <Text 
            tagName="h2" 
            headingSize="m" 
            className={style.stepTitle}>
              <span>1</span>
              {t("new-action.stepOne")}
          </Text>
          
          <div className="row" style={pa === "null" ? {marginBottom: "9rem"} : {marginBottom: "0"}}>
            <Select
              formField={{className:"col-12"}}
              options={pas}
              label="PA"
              value={pa}
              onChange={changePa}
              id="pa"
            />
            <Select
                formField={{className:"col-6"}}
                label={t("new-action.orderBy")}
                onChange={changeOrder}
                id="orderBy"
                defaultValue={orderOptions[0]}
                options={orderOptions}
            />
            <Select
              formField={{className:"col-6"}}
              label={t("new-action.view")}
              onChange={changeView}
              id="viewArticles"
              defaultValue={viewOptions[0]}
              options={viewOptions}
            />
          </div>
          
          
          {loading2 &&
            <Load />
          }
          {!loading2 && articles.length > 0 &&
            <div ref={articlesRef} className="pt-3 row">
              {articles?.map(article=>(
                <ArticleCard 
                  key={article.item} 
                  props={article}
                  flow={flow}
                  handleChange={updateArticleSelected}
                  />
              ))}
              {articlesSelected.length > 0 && 
              <>
                <div ref={formRef}></div>
                <Button 
                  type="emphasised" 
                  iconOnly 
                  size="small" 
                  ssrIcon={arrowDown} 
                  className={style.buttonDown}
                  onClick={() => setTimeout(()=>formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100)}
                />
              </>
              }
            </div>
          }
          
           {/* STEP TWO */}
          {articlesSelected.length > 0 &&
            <form onSubmit={handleSubmit(goToSaveAction)} >
              <Text 
                tagName="h2" 
                headingSize="m" 
                className={style.stepTitle}>
                  <span>2</span>
                  {t("new-action.stepTwo")}: {articlesSelected.map((el, index)=> {
                    if(index === 0){
                      return el
                    } else{
                      return ', ' + el
                    }
                  })} 
              </Text>
              <div className="row">
                <Controller
                  control={control}
                  name="actionType"
                  rules={{required: true }}
                  render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                  <Select
                    formField={{
                      className:"col-12",
                      shouldValidate: error !== undefined,
                      validation:{
                        id:"error-msg-actionType",
                        msg:t("validation.required"),
                        type: "error"
                      }
                    }}
                    label={<RequiredField text={t("new-action.actionType")}/>}
                    value={actions.find((c) => c.value === value)}
                    onChange={val => {
                      onChange(val.value)
                      setActionSelected(val.value)
                    }}
                    inputRef={ref}
                    options={actions}
                    id="actionType"
                  />
                  )} 
                />
                
                <Controller
                  control={control}
                  name="startDate"
                  rules={{required: true }}
                  render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                  <Select
                    formField={{
                      className:"col-6",
                      shouldValidate: error !== undefined,
                      validation:{
                        id:"error-msg-startDate",
                        msg:t("validation.required"),
                        type: "error"
                      }
                    }}
                    label={<RequiredField text={t("new-action.start")}/>}
                    value={optionsWeekStart.find((c) => c.value === value)}
                    onChange={val => {
                      onChange(val.value)
                      setStart(val.value)
                    }}
                    inputRef={ref}
                    options={optionsWeekStart}
                    id="startDate"
                  />
                  )} 
                />

                <Controller
                  control={control}
                  name="endDate"
                  rules={{required: true }}
                  render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                  <Select
                    formField={{
                      className:"col-6",
                      shouldValidate: error !== undefined,
                      validation:{
                        id:"error-msg-endDate",
                        msg:t("validation.required"),
                        type: "error"
                      }
                    }}
                    label={<RequiredField text={t("new-action.end")}/>}
                    value={optionsWeekEnd.find((c) => c.value === value)}
                    onChange={val => {
                      onChange(val.value)
                      setEnd(val.value)
                    }}
                    inputRef={ref}
                    options={optionsWeekEnd}
                    id="endDate"
                  />
                  )} 
                />

                <Controller
                  control={control}
                  name="responsible"
                  rules={{required: true }}
                  render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                  <Select
                    formField={{
                      className:"col-12",
                      shouldValidate: error !== undefined,
                      validation:{
                        id:"error-msg-responsible",
                        msg:t("validation.required"),
                        type: "error"
                      }
                    }}
                    label={<RequiredField text={t("new-action.responsible")}/>}
                    value={employees.find((c) => c.value === value)}
                    onChange={val => {
                      setResponsible(val)
                      onChange(val.value)
                    }}
                    inputRef={ref}
                    options={employees}
                    isSearchable={true}
                    id="responsible"
                  />
                  )} 
                />

                <Controller
                  control={control}
                  name="contributor"
                  rules={{required: true }}
                  render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                  <Select
                    formField={{
                      className:"col-12",
                      shouldValidate: error !== undefined,
                      validation:{
                        id:"error-msg-contributor",
                        msg:t("validation.required"),
                        type: "error"
                      }
                    }}
                    label={<RequiredField text={t("new-action.contributor")}/>}
                    value={employees.find((c) => c.value === value)}
                    onChange={val => {
                      onChange(val.value)
                      setContributor(val)
                    }}
                    inputRef={ref}
                    options={employees}
                    isSearchable={true}
                    id="contributor"
                  />
                  )} 
                />
                <Controller
                  control={control}
                  name="description"
                  rules={{maxLength: 1000, required: true }}
                  render={({ field:{ onChange, ref}, fieldState: {error}}) => (
                    <TextArea
                      formField={{
                        className:"col-12",
                        characterLimit:1000,
                        shouldValidate: error !== undefined,
                          validation:{
                            id:"error-msg-description",
                            msg:t("validation.maxLength"),
                            type: "error"
                          }
                      }}
                      textArea={{
                        label: <RequiredField text={t("new-action.description")}/>,
                        value: description,
                        onChange: e => {
                          onChange(e.target.value)
                          setDescription(e.target.value)
                        },
                      }}
                      inputRef={ref}
                    />
                  )} 
                />
                <UploadFile handleFiles={handleFiles}/>
                <Button
                  className={!buttonSubmitActive ? style.submitButton : 'col-12'}
                  type="emphasised"
                  text={t("new-action.save")}
                  htmlType="submit"
                  loading={loadingButton}
                />
              </div>
            </form>
          }
        </div>
      </>
      }
    </>
  )
}
