import "@ingka/focus/style.scss";
import "@ingka/forms/style.scss";

import RadioButtonGroup from "@ingka/radio-button-group";
import FormField from "@ingka/form-field";

export default function RadioGroup({formField, radio}) {
  return (
    <FormField
      {...formField}
    >
      <RadioButtonGroup
        {...radio}
      />
    </FormField>
  )
}
