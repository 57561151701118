import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import {store} from "./store/store"
import { BrowserRouter } from 'react-router-dom';

//Langues
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import global_es from "./languages/es/global.json"
import global_en from "./languages/en/global.json"

//STYLE
import "./assets/scss/main.scss"

//GOOGLE TAG MANAGER
/* import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-000000'
}
TagManager.initialize(tagManagerArgs) */


//LANGUAGES
i18next.init({
  interpolation:{ escapeValue: false },
  lng: "es",
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
