import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/carousel/style.scss";
import "@ingka/tabs/style.scss";
import "@ingka/focus/style.scss";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";

export default function MyTab({tabs, tabPanels, defaultTab}) {
  return (
    <Tabs 
      tabs={
        tabs.map((tab, index) =>
          <Tab key={index} {...tab}/>
        )
      } 
      tabPanels={
        tabPanels.map((panel, index) =>
          <TabPanel key={index} {...panel}/>
        )
      } 
      defaultActiveTab={defaultTab}
      />
  )
}
