import { createSlice } from "@reduxjs/toolkit";
import { calculateMonthOptions } from "../utils/calculateMonthOptions";

const initialState = {
  language: localStorage.getItem("language"),
  login: !!localStorage.getItem("login"),
  token: localStorage.getItem("token") || null,
  user: localStorage.getItem("user"),
  userName: localStorage.getItem("userName"),
  email: localStorage.getItem("email"),
  rol: localStorage.getItem("rol"),
  toasts: [],
  flowSelected: JSON.parse(localStorage.getItem("flowSelected")),
  storeSelected: JSON.parse(localStorage.getItem("storeSelected")),
  filtersSelected: JSON.parse(localStorage.getItem("filtersSelected")) || {},
  myStore: JSON.parse(localStorage.getItem("myStore")),
  week: localStorage.getItem("week"),
  beforeWeek: localStorage.getItem("beforeWeek"),
  path: localStorage.getItem("path"),
  page: localStorage.getItem("page"),
  lastDateData: JSON.parse(localStorage.getItem("lastDateData")) || null,
  year: localStorage.getItem("year"),
  monthOptions: JSON.parse(localStorage.getItem("monthOptions")) || [],
};

export const globalSlice = createSlice(
  {
  name: "global",
  initialState,
  reducers: {
    
    doToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", state.token)
    },

    doLogin: (state,action) => {
      state.user = action.payload.user
      state.userName = action.payload.userName
      state.rol = action.payload.rol
      state.email = action.payload.email
      state.myStore = action.payload.myStore
      localStorage.setItem("user", state.user)
      localStorage.setItem("userName", state.userName)
      localStorage.setItem("email", state.email)
      localStorage.setItem("rol", state.rol)
      localStorage.setItem("myStore", JSON.stringify(state.myStore))
      state.filtersSelected = {
        store:null, 
        hfb:null, 
        period: null, 
        pa:null,
        myActions: true,
        state: "active",
        flow: {id: "0", name: "All"},
        department: null,
        categoryProductivity: null,
        month: null
      }
      state.flowSelected = null
    },

    updateMyStore: (state,action) => {
      state.myStore = action.payload
      localStorage.setItem("myStore", JSON.stringify(state.myStore))
    },
    updateStoreSelected: (state,action) => {
      state.storeSelected = action.payload
      localStorage.setItem("storeSelected", JSON.stringify(state.storeSelected))
    },

    doLoginFinalize: (state,action)=>{
      state.login = action.payload
      localStorage.setItem("login", state.login)
    },

    doLogout: (state,action) => {
      state.login = false
      state.user = null
      state.token = null
      localStorage.clear()
    },

    changeLang: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("language", state.language)
    },

    updateActualDate: (state)=>{
      const date = new Date();
      const startDate = new Date(date.getFullYear(),0,1);
      const numberOfDays = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
      const indexWeek = (Math.floor(( date.getDay() + 1 + numberOfDays) / 7))
      const week = indexWeek+1
      state.week = week
      localStorage.setItem("week", state.week)
      state.beforeWeek = week - 1
      localStorage.setItem("beforeWeek", state.beforeWeek)
      
      const naturalMonth = date.getMonth() + 1
      const naturalYear = date.getFullYear() 
      state.year = naturalMonth < 9 ? naturalYear : naturalYear + 1
      localStorage.setItem("year", state.year)
    },

    createMsgToast: (state, action) => {
      state.toasts = [...state.toasts, action.payload]
    },
    deleteMsgToast: (state, action) => {
      state.toasts = state.toasts.slice(0, action.payload)
    },

    updateLastDateData: (state, action)=>{
      state.lastDateData = action.payload
      localStorage.setItem("lastDateData", JSON.stringify(state.lastDateData))

      state.monthOptions = calculateMonthOptions(action.payload.month)
      localStorage.setItem("monthOptions", JSON.stringify(state.monthOptions))
    },

    updateFlowSelected: (state, action)=>{
      state.flowSelected = action.payload
      localStorage.setItem("flowSelected", JSON.stringify(state.flowSelected))
    },
    updateFilterHfb: (state, action) =>{
      if(state.filtersSelected !== null){
        state.filtersSelected.hfb = action.payload
        localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
      }
      
    },
    updateFilterPeriod: (state, action) =>{
      state.filtersSelected.period = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },
    updateFilterStore: (state, action) =>{
      state.filtersSelected.store = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },
    updateFilterPa: (state, action) =>{
      state.filtersSelected.pa = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },

    updateFilterState: (state, action) =>{
      state.filtersSelected.state = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },
    updateFilterMyActions: (state, action) =>{
      state.filtersSelected.myActions = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },
    updateFilterFlow: (state, action) =>{
      state.filtersSelected.flow = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },

    updateFilterDepartment: (state, action) =>{
      state.filtersSelected.department = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },

    updateFilterCategoryProductivity: (state, action) =>{
      state.filtersSelected.categoryProductivity = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },

    updateFilterMonth: (state, action) =>{
      state.filtersSelected.month = action.payload
      localStorage.setItem("filtersSelected", JSON.stringify(state.filtersSelected))
    },

    updatePath: (state, action) =>{
      state.path = action.payload
      localStorage.setItem("path", state.path)
    },

    updatePage: (state, action) =>{
      state.page = action.payload
      localStorage.setItem("page", state.page)
    }
  },
});

export const { 
  doToken,
  changeLang,
  updateMyStore,
  doLogin, 
  doLoginFinalize,
  doLogout, 
  updateActualDate,
  createMsgToast,
  deleteMsgToast,
  updateFlowSelected,
  updateFilterHfb,
  updateFilterPeriod,
  updateFilterStore,
  updateFilterPa,
  updateFilterState,
  updateFilterMyActions,
  updateFilterFlow,
  updatePath,
  updatePage,
  updateStoreSelected,
  updateFilterDepartment,
  updateFilterCategoryProductivity,
  updateFilterMonth,
  updateLastDateData
} = globalSlice.actions;
export default globalSlice.reducer;