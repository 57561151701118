import { apiFetch } from '../configuration/api/apiFetch';
import { API_URLS } from '../configuration/api/apiConstants';

export const getPeriods = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.PERIODS
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response === undefined) throw  response
      resolve(response)
    }).catch(() => reject())
  })
  return dataFetch
}

export const getHfbs = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.HFBS
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response === undefined) throw  response
      resolve(response)
    }).catch(() => reject())
  })
  return dataFetch
}

export const getPas = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.PA,
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response) resolve(response)
    })
  })
  return dataFetch
}

export const getStores = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.STORES
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response === undefined) throw  response 
      resolve(response)
    }).catch(() => reject())
  })
  return dataFetch
}

export const getDepartments = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.DEPARTMENTS
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response === undefined) throw  response 
      resolve(response)
    }).catch(() => reject())
  })
  return dataFetch
}

export const getCategoryProductivity = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.CATEGORY_PRODUCTIVITY,
    params
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response === undefined) throw  response 
      resolve(response)
    }).catch(() => reject())
  })
  return dataFetch
}

export const getDateAvailabilityFlow7 = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.DATE_FLOW7
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response === undefined) throw  response 
      resolve(response)
    }).catch(() => reject())
  })
  return dataFetch
}

export const getInfo = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.INFO,
    params
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response === undefined) throw  response 
      resolve(response)
    }).catch(() => reject())
  })
  return dataFetch
}