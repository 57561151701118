/* eslint-disable react-hooks/exhaustive-deps */
import "@ingka/svg-icon/style.scss";
import "@ingka/button/style.scss";
import "@ingka/modal/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/svg-icon/style.scss";
import "@ingka/accordion/style.scss";
import "@ingka/focus/style.scss";
import "@ingka/carousel/style.scss";
import Modal, { Sheets, ModalHeader, ModalFooter, ModalBody } from "@ingka/modal";
import { OverflowCarousel } from "@ingka/carousel";
import Accordion, { AccordionItem } from "@ingka/accordion";
import Button from "../content/Button";
import { useEffect, useState } from "react";
import filterIcon from '@ingka/ssr-icon/paths/filters'
import { useTranslation } from "react-i18next";
import style from "../../assets/scss/components/global/filter.module.scss"
import cross from '@ingka/ssr-icon/paths/cross-small'
import { useDispatch, useSelector } from "react-redux";
import RadioGroup from "../form/RadioGroup";
import { updateFilterCategoryProductivity, updateFilterDepartment, updateFilterFlow, updateFilterHfb, updateFilterMonth, updateFilterMyActions, updateFilterPa, updateFilterPeriod, updateFilterState, updateFilterStore, updateFlowSelected } from "../../store/globalSlice";
import { setCategoryProductivity, setPas } from "../../store/filtersSlice";
import Switch from "../form/Switch";
import { COWORKER } from "../../utils/roles";
import { getCategoryProductivity, getPas } from "../../services/mainServices";


export default function Filter() {
  const[modalOpen, setModalOpen] = useState(false)
  const hfbs = useSelector((state) => state.filters.hfbs)
  const periods = useSelector((state) => state.filters.periods)
  const stores = useSelector((state) => state.filters.stores)
  const filters = useSelector((state) => state.global.filtersSelected)
  const week = useSelector((state) => state.global.beforeWeek)
  const pas = useSelector((state) => state.filters.pas)
  const flowSelected = useSelector((state) => state.global.flowSelected)
  const page = useSelector((state) => state.global.page)
  const myStore = useSelector((state) => state.global.myStore)
  const rol = useSelector((state) => state.global.rol)
  const hfbSelected = useSelector((state) => state.global.filtersSelected.hfb)
  const flowsRaw = useSelector((state) => state.flows)
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const [paVisible, setPaVisible] = useState(false)
  const [hfbVisible, setHfbVisible] = useState(true)
  const [periodVisible, setPeriodVisible] = useState(true)
  const [storeVisible, setStoreVisible] = useState(true)
  const [inActions, setInActions] = useState(false)
  const flows = [{id:0, name: "All"}, ...flowsRaw]
  const [productivityFiltersVisible, setProductivityFiltersVisible] = useState(false)
  const departments = useSelector((state) => state.filters.departments)
  const categories = useSelector((state) => state.filters.categoryProductivity)
  const months = useSelector((state) => state.global.monthOptions)
  const lastDateData = useSelector((state) => state.global.lastDateData)

  const clearFilter = (type)=>{
    switch (type){
      case "hfb":
        dispatch(updateFilterHfb(null))
        break;
      case "period":
        dispatch(updateFilterPeriod(null))
        break;
      case "pa":
        dispatch(updateFilterPa(null))
        break;
      default:
        return false
    }
  }
  
  const changeHfb = (e)=>{
    setModalOpen(!modalOpen)
    dispatch(updateFilterHfb({id:e.target.value, name:e.target.name}))
  }

  const changePeriod = (e)=>{
    setModalOpen(!modalOpen)
    dispatch(updateFilterPeriod({id:e.target.value, name:e.target.name}))
  }

  const changeStore = (e)=>{
    setModalOpen(!modalOpen)
    dispatch(updateFilterStore({id:e.target.value, name:e.target.name}))
  }

  const changePa = (e)=>{
    setModalOpen(!modalOpen)
    dispatch(updateFilterPa({id:e.target.value, name:e.target.name}))
  }

  const changeFlow = (e)=>{
    setModalOpen(!modalOpen)
    dispatch(updateFilterFlow({id:e.target.value, name:e.target.name}))
  }

  const changeMyActions = (e)=>{
    setStoreVisible(!e.target.checked)
    dispatch(updateFilterMyActions(e.target.checked))
    setModalOpen(!modalOpen)
  }

  const changeState = (e)=>{
    setModalOpen(!modalOpen)
    dispatch(updateFilterState(e.target.value))
  }

  const changeDepartment = (e)=>{
    dispatch(updateFilterDepartment(e.target.value))
    dispatch(updateFilterCategoryProductivity(null))
    goToGetCategories(e.target.value)
  }

  const goToGetCategories = (department) => {
    getCategoryProductivity({department: department}).then( cats => {
      dispatch(setCategoryProductivity(cats))
      dispatch(updateFilterCategoryProductivity(cats[0]))
    })
  }

  const changeCategory= (e)=>{
    setModalOpen(!modalOpen)
    dispatch(updateFilterCategoryProductivity(e.target.value))
  }

  const changeMonth= (e)=>{
    setModalOpen(!modalOpen)
    dispatch(updateFilterMonth(e.target.value))
  }

  useEffect(()=>{
    const thePage = window.location.pathname
    if(thePage !== "/" || page !== "actions"){
      if(("flow2" === page || "flow3" === page || "flow4" === page || "flow5" === page) && hfbSelected !== null){
        dispatch(updateFilterPa(null))
        getPas({hfb:hfbSelected?.id}).then( response => {
          dispatch(setPas(response))
          dispatch(updateFilterPa(response[0]))
        })
      }
    }
  },[hfbSelected])

  useEffect(()=>{
    switch (page){
      case "dashboard":
        dispatch(updateFlowSelected(null))
        setPaVisible(false)
        setHfbVisible(true)
        setStoreVisible(true)
        setInActions(false)
        setPeriodVisible(true)
        setProductivityFiltersVisible(false)
        break;
      case "new-action":
        setPaVisible(false)
        setStoreVisible(rol !== COWORKER ? true : false)
        setHfbVisible(flowSelected.id !== 7 ? true : false)
        dispatch(updateFilterStore(myStore))
        setInActions(false)
        setPeriodVisible(true)
        setPeriodVisible(false)
        setProductivityFiltersVisible(false)
        break;
      case "actions":
        dispatch(updateFlowSelected(null))
        dispatch(updateFilterHfb(null))
        dispatch(updateFilterPa(null))
        dispatch(updateFilterMyActions(true))
        setHfbVisible(true)
        setPaVisible(false)
        setStoreVisible(false)
        setInActions(true)
        setPeriodVisible(false)
        setProductivityFiltersVisible(false)
        break;
      case "flow1":
        dispatch(updateFilterPa(null))
        setHfbVisible(true)
        setPaVisible(false)
        setStoreVisible(true)
        setInActions(false)
        setPeriodVisible(true)
        break;
      case "flow2": case "flow3": case "flow4": case "flow5":
        if(!filters.pa) dispatch(updateFilterPa(pas[0]))
        setHfbVisible(true)
        setPaVisible(true)
        setStoreVisible(true)
        setInActions(false)
        setPeriodVisible(true)
        break;
      case "flow7": 
        setPaVisible(false)
        setStoreVisible(true)
        setInActions(false)
        setPeriodVisible(false)
        setProductivityFiltersVisible(true)
        setHfbVisible(false)
        break;
      default:
        break;
    }
  },[page])

  return (
    <>
    {filters && week !== undefined && hfbs && periods &&
      <>
      <div className={style.containerFilters}>
        <div className={style.filterButtons}>
          <OverflowCarousel
          controlBtnType="primary"
          id="demo">
            <Button 
              onClick={() => setModalOpen(!modalOpen)}
              type="tertiary"
              iconOnly
              ssrIcon={filterIcon}
              size="small"
            />
            <Button 
              type="tertiary"
              text={filters.myActions && inActions ? t('actions.myActions') : filters.store.id}
              size="small"
              className={style.filterButton}
              onClick={()=>setModalOpen(!modalOpen)}
            />
            
            {filters.period && periodVisible &&
              <Button 
              type="tertiary"
              text={`${filters.period.id} ${filters.period.id ==="WEEK" ? week : ''}`}
              size="small"
              className={style.filterButton}
              onClick={()=>setModalOpen(!modalOpen)}
            />
            }
            {inActions &&
              <Button 
              type="tertiary"
              text={filters.state === "all" ? t('global.state')+": "+t('actions.'+filters.state) : t('actions.'+filters.state)}
              size="small"
              className={style.filterButton}
              onClick={()=>setModalOpen(!modalOpen)}
            />
            }
            {inActions &&
              <Button 
              type="tertiary"
              text={`${t('actions.flow')}: ${filters.flow.id === "0" ? t('global.all') : filters.flow.id}`}
              size="small"
              className={style.filterButton}
              onClick={()=>setModalOpen(!modalOpen)}
            />
            }
            {filters.hfb && page !== "flow7" && flowSelected?.id !== 7 &&
              <Button 
              onClick={()=>{
                if(flowSelected === null){
                  clearFilter("hfb")
                } else{
                  setModalOpen(!modalOpen)
                }
              }}
              type="tertiary"
              text={`HFB ${filters.hfb.id}`}
              ssrIcon={flowSelected === null ? cross : null}
              size="small"
              className={style.filterButton}
            />
            }
            {filters.pa && page !== "flow1" && page !== "dashboard" && page !== "flow7" &&
              <Button 
              onClick={()=>{
                if(flowSelected === null){
                  clearFilter("pa")
                } else{
                  setModalOpen(!modalOpen)
                }
              }}
              type="tertiary"
              text={`PA ${filters.pa.id}`}
              ssrIcon={flowSelected === null ? cross : null}
              size="small"
              className={style.filterButton}
            />
            }
            {page === "flow7" && page !== "dashboard" &&
              <>
                <Button 
                  onClick={()=>{
                    setModalOpen(!modalOpen)
                  }}
                  type="tertiary"
                  text={t(`months.${filters.month}`)}
                  size="small"
                  className={style.filterButton}
                />
                <Button 
                  onClick={()=>{
                    setModalOpen(!modalOpen)
                  }}
                  type="tertiary"
                  text={filters.department}
                  size="small"
                  className={style.filterButton}
                />
                <Button 
                  onClick={()=>{
                    setModalOpen(!modalOpen)
                  }}
                  type="tertiary"
                  text={filters.categoryProductivity}
                  size="small"
                  className={style.filterButton}
                />
              </>
            }
          </OverflowCarousel>
          <hr/>
        </div>
      </div>

      <Modal
        visible={modalOpen}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={()=> setModalOpen(!modalOpen)}
        >
        <Sheets
          aria-label="Accessibility header for a modal"
          header={<ModalHeader className={style.modal_header} title={t("components.filter.title")}/>}
          footer={<ModalFooter ></ModalFooter>}
        >
          <ModalBody>
            {/* SWITCH FOR ACTIONS */}
            {inActions &&
            <>
              <Switch props={{
                className:"mb-2",
                id: "myActions",
                name: "My_actions",
                label: t("actions.myActions"),
                checked: filters.myActions,
                onChange: changeMyActions
              }}
              />
              <Accordion collapsible={false}>
                <AccordionItem
                  caption={filters.state && t('actions.'+filters.state) }
                  id="flows"
                  title={t('global.state')}
                > 
                  <RadioGroup
                  radio={{
                    onChange: changeState,
                    list: [
                      {
                        id: 'all',
                        label: t('actions.all'),
                        value: 'all',
                        name: 'all',
                        checked: filters.state === "all",
                      },
                      {
                        id: 'active',
                        label: t('actions.active'),
                        value: 'active',
                        name: 'active',
                        checked: filters.state === "active",
                      },
                      {
                        id: 'pending',
                        label: t('actions.pendingState'),
                        value: 'pending',
                        name: 'pending',
                        checked: filters.state === "pending",
                      },
                      {
                        id: 'finalize',
                        label: t('actions.finalize'),
                        value: 'finalize',
                        name: 'finalize',
                        checked: filters.state === "finalize",
                      },
                    ]
                  }}/>
                </AccordionItem>
              </Accordion>
            </>
            }

            {/* FLOWS */}
            {inActions &&
              <Accordion collapsible={false}>
                <AccordionItem
                  caption={filters.flow && `${filters.flow.id} - ${filters.flow.id === 0 ? t('global.all') : filters.flow.name}`}
                  id="flows"
                  title={t('actions.flow')}
                > 
                  <RadioGroup
                  radio={{
                    list:
                      flows?.map(flow=>(
                        {disabled: false,
                        id: flow.id,
                        label: `${flow.id} - ${flow.id === "0" ? t('global.all') : flow.name}`,
                        name: flow.id === "0" ? t('global.all') : flow.name,
                        value: flow.id,
                        checked: filters.flow && parseInt(flow.id) === parseInt(filters.flow.id),
                        onChange: changeFlow
                      }
                      ))
                  }}/>
                </AccordionItem>
              </Accordion>
            }
            
            {/* STORES */}
            {storeVisible && 
              <Accordion collapsible={false}>
                <AccordionItem
                  caption={filters.store && `${filters.store.id} - ${filters.store.name}`}
                  id="stores"
                  title="Store"
                > 
                  <RadioGroup
                  radio={{
                    list:
                      stores?.map(store=>(
                        {disabled: false,
                        id: store.id,
                        label: `${store.id} - ${store.name}`,
                        name: store.name,
                        value: store.id,
                        checked: filters.store && store.id === filters.store.id,
                        onChange: changeStore
                      }
                      ))
                  }}/>
                </AccordionItem>
              </Accordion>
            }

            {/* PERIODS */}
            {periodVisible &&
              <Accordion collapsible={false}>
                <AccordionItem
                  caption={filters.period && `${filters.period.id} ${filters.period.id === "WEEK" ? " - "+week : ""}`}
                  id="period"
                  title="Period"
                > 
                  <RadioGroup
                  radio={{
                    list:
                      periods?.map(period=>(
                        {disabled: false,
                        id: period.id,
                        name: period.name,
                        label: `${period.id} - ${period.name}`,
                        value: period.id,
                        checked: filters.period && period.id === filters.period.id,
                        onChange: changePeriod
                      }
                      ))
                  }}/>
                </AccordionItem>
              </Accordion>
            }
            
            {/* HFB */}
            {!productivityFiltersVisible && hfbVisible &&
              <Accordion collapsible={false}>
                <AccordionItem
                  caption={filters.hfb && `${filters.hfb.id} - ${filters.hfb.name}`}
                  id="hfb"
                  title="HFB"
                > 
                  <RadioGroup
                  radio={{
                    list:
                      hfbs?.map(hfb=>(
                        {disabled: false,
                        id: hfb.id,
                        label: `${hfb.id} - ${hfb.name}`,
                        name:hfb.name,
                        value: hfb.id,
                        checked: filters.hfb && hfb.id === filters.hfb.id,
                        onChange: changeHfb
                      }
                      )),
                  }}/>
                </AccordionItem>
              </Accordion>
            }
            
            
            {/* PA */}
            {paVisible && hfbSelected !== null &&
            <Accordion collapsible={false}>
              <AccordionItem
                caption={filters.pa && `${filters.pa.id} - ${filters.pa.name}`}
                id="pa"
                title="PA"
              > 
                <RadioGroup
                radio={{
                  list:
                    pas?.map(pa=>(
                      {disabled: false,
                      id: pa.id,
                      label: `${pa.id} - ${pa.name}`,
                      name: pa.name,
                      value: pa.id,
                      checked: filters.pa && pa.id === filters.pa.id,
                      onChange: changePa
                    }
                    ))
                }}/>
              </AccordionItem>
            </Accordion>
            }

            {/* PRODUCTIVITY */}
            {productivityFiltersVisible &&
            <>
              <Accordion collapsible={false}>
                <AccordionItem
                  caption={filters.month && t(`months.${filters.month}`)}
                  id="month"
                  title={`${t('components.filter.month')} ${lastDateData.year}`}
                > 
                  <RadioGroup
                  radio={{
                    list:
                      months?.map(month=>(
                        {disabled: false,
                        id: month,
                        label: t(`months.${month}`),
                        name: t(`months.${month}`),
                        value: month,
                        checked: filters.month && month === filters.month,
                        onChange: changeMonth
                      }
                      ))
                  }}/>
                </AccordionItem>
              </Accordion>

              <Accordion collapsible={false}>
                <AccordionItem
                  caption={filters.department && `${filters.department}`}
                  id="department"
                  title={t('global.department')}
                > 
                  <RadioGroup
                  radio={{
                    list:
                      departments?.map(dep=>(
                        {disabled: false,
                        id: dep,
                        label: `${dep}`,
                        name: dep,
                        value: dep,
                        checked: filters.department && dep === filters.department,
                        onChange: changeDepartment
                      }
                      ))
                  }}/>
                </AccordionItem>
              </Accordion>

              <Accordion collapsible={false}>
                <AccordionItem
                  caption={filters.categoryProductivity && `${filters.categoryProductivity}`}
                  id="category"
                  title={t('components.filter.productivityCategory')}
                > 
                  <RadioGroup
                  radio={{
                    list:
                      categories?.map(cat=>(
                        {disabled: false,
                        id: cat,
                        label: `${cat}`,
                        name: cat,
                        value: cat,
                        checked: filters.categoryProductivity && cat === filters.categoryProductivity,
                        onChange: changeCategory
                      }
                      ))
                  }}/>
                </AccordionItem>
              </Accordion>
            </>
            }
          </ModalBody>
        </Sheets>
      </Modal>
      </>
      }
    </>
  )
}