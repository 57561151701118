/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export default function useDateOptions() {
  const [t] = useTranslation("global")
  const actualWeek = useSelector((state) => state.global.week)
  const [optionsWeekStart, setOptionsWeekStart] = useState(["hola"])
  const [optionsWeekEnd, setOptionsWeekEnd] = useState([])
  const [optionsMonthStart, setOptionsMonthStart] = useState([])
  const [optionsMonthEnd, setOptionsMonthEnd] = useState([])

  useEffect(() => {
    calculateWeeks()
    calculateMonths()
  },[actualWeek])

  const calculateWeeks = () => {
    const start = []
    const end = []
    const MAX_WEEK = 52
    let week = parseInt(actualWeek)
    for (let i = 0; i <= 7; i++) {
      if (week+i > MAX_WEEK) {
          week -= MAX_WEEK;
      }
      if(i <=2){
        start.push({label: t('global.week') + ' ' + (week+i), value: week+i})
      }
      end.push({label: t('global.week') + ' ' + (week+i), value: week+i})
    }
    setOptionsWeekStart(start)
    setOptionsWeekEnd(end)
  }

  const calculateMonths = () => {
    const start = []
    const end = []
    const MAX_MONTH = 12
    const date = new Date()
    const actualMonth = date.getMonth()+1
    let month = actualMonth
    for (let i = 0; i <= 7; i++) {
      if (month+i > MAX_MONTH) {
          month -= MAX_MONTH;
      }
      if(i <=2){
        start.push({label: t(`months.${month+i}`), value: month+i})
      }
      end.push({label: t(`months.${month+i}`), value: month+i})
    }
    setOptionsMonthStart(start)
    setOptionsMonthEnd(end)
  }
    
  return ({
    optionsWeekStart,
    optionsWeekEnd,
    optionsMonthStart,
    optionsMonthEnd
  })
}
