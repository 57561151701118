import axios from "axios";
import t  from "../../languages/es/global.json"
import { createMsgToast, doLogout } from "../../store/globalSlice";
import {store} from "../../store/store"

export async function apiFetch({
  method,
  url, 
  params = null,
  data = null, 
  contentType = "application/json",
  headerParams = true,
  api = process.env.REACT_APP_API,
  successMsg = null,
  }) {

  const lang = localStorage.getItem("language") || "es"
  const token = localStorage.getItem("token")
  const user = localStorage.getItem("user")
  const rol = localStorage.getItem("rol")
  const header = {
    "Content-Type": contentType,
    ...(headerParams ? {
      'Authorization': 'Bearer '+token,
      'originUser': user,
      "Accept-Language": lang,
      'originUserRole': rol
    } : {})
  }

  try {
    let response
    response = await axios({
      method: method,
      url: api+url,
      ...(params ? {params : params} : {}),
      ...(data ? {data : data} : {}),
      headers: header,
    });

    if (response.data) {
      if(successMsg) {
        store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
      }
      return response.data;
    }
  } catch (error) {
    let errorFormat = error
    if(error.code === "ERR_NETWORK"){
      errorFormat = {
        response: error.name,
        message: error.message
      }
    }
    if (error.response) {
      if (error.response.status === 422 || error.response.status === 401) {
        store.dispatch(doLogout())
        errorFormat = {
          response: error.code,
          message: error.response.data.message === "token.invalidExpired" ? t.global.tokenExpired : error.response.data.message
        };
      }
      if (error.response.status === 404) {
        window.location.href = "/not-found";
      }
      if(error.response.status === 409){
        errorFormat = {
          response: error.name,
          message:error.response.data.message
        }
      }
    }
    store.dispatch(createMsgToast({body: errorFormat.message, title:"Error", variant:"negative"}))
  }
}