import FormField from "@ingka/form-field";
import "@ingka/forms/style.scss";
import TextA from "@ingka/text-area";

export default function TextArea({formField, textArea}) {
  return (
    <FormField
      {...formField}
    >
      <TextA
        {...textArea}
      />
    </FormField>
  )
}
