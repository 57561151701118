
export function RegularizeDataTable(data, flow, hfbFull, storeFull, paFull, categoryProductivity) {
  const dataTable = data.body
  const store = storeFull.id
  const hfb = hfbFull.id
  const pa = paFull !== null ? paFull.id : ''

  switch(flow.id){
    case 1:
      dataTable.reverse()
      dataTable.forEach((tab, index)=>{
        if(index === 0)tab.colPing = "store"
        //tab.columns.reverse()
        tab.short = {
          id: "index_sales_vs_goal_last_week", 
          desc: true,
        }

        tab.data.forEach((row)=> {
          if(row.store === store || row.hfb === hfb) row.selected = true
        })

        tab.columns.forEach((col)=>{
          col.accessorKey = col.key
          col.header = col.label
          if(col.key === tab.colorfor){
            col.Cell = ({cell, row}) => <span 
            style={{color: cell.column.id === tab.colorfor ?  row.original.value_color : '#000',
              }}>
              <b>{cell.getValue()}</b>
            </span>
            col.muiTableHeadCellProps = {
              align: col.key === "index_sales_vs_goal_last_week" ? 'right' : 'left',
            }
            col.muiTableBodyCellProps = {
              align: col.key === "index_sales_vs_goal_last_week" ? 'right' : 'left',
            }
            col.size = 100
          }else{
            col.size = col.key === "store" || col.key === "hfb" ? 40 : 150
            col.muiTableBodyCellProps = {
              sx:{
                maxWidth: col.key === "store" || col.key === "hfb" ? 40 : 150,
                textWrap: "wrap"
              }
            }
          }
        })
      })
      break;

    case 2:
      dataTable.forEach((tab)=>{
        tab.colPing = tab.columns[0].key

        tab.short = {
          id: tab.colorfor, 
          desc: true,
        }

        tab.data.forEach((row)=> {
          if(row.store === store || row.hfb === hfb || row.pa === pa) row.selected = true

        })

        tab.columns.forEach((col, index)=>{
          col.accessorKey = col.key
          col.header = col.label
          if(col.key === tab.colorfor){
            col.Cell = ({cell, row}) => <span 
              style={{color: cell.column.id === tab.colorfor ?  row.original.value_color : '#000',
              }}>
              <b>{cell.getValue()}</b>
            </span>
          
          }

          
          if(index === 0 ){
            col.muiTableHeadCellProps={
              sx: {
                minWidth: 60,
                width: 60,
                maxWidth: 60,
              },
            }
            col.muiTableBodyCellProps={
              sx: {
                minWidth: 60,
                width: 60,
                maxWidth: 60,
              },
            }
          }
          else{
            col.muiTableHeadCellProps={
              sx: {
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                textWrap: "wrap"
              },
            }
            col.muiTableBodyCellProps={
              sx: {
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                textWrap: "wrap"
              },
            }
          }
        })
      })
      break;
    case 3: case 4:
      dataTable.forEach((tab)=>{
        tab.short = {
          id: tab.colorfor, 
          desc: true,
        }

        tab.data.forEach((row)=> {
          if(row.store === store || row.hfb === hfb || row.pa === pa) row.selected = true
        })

        tab.columns.forEach((col, index)=>{
          col.accessorKey = col.key
          col.header = col.label
          if(col.key === tab.colorfor){
            col.Cell = ({cell, row}) => <span 
              style={{color: cell.column.id === tab.colorfor ?  row.original.value_color : '#000',
              }}>
              <b>{cell.getValue()}</b>
            </span>
            
          }
          if(index === 0){
            col.size = 60
          }else if(index === 1){
            col.size = 120
            col.muiTableHeadCellProps = {
              align: 'left',
            }
            col.muiTableBodyCellProps = {
              align: 'left',
              sx:{
                maxWidth: 140,
                textWrap: "wrap"
              }
            }
          }
          else{
            col.size = 120
            col.muiTableHeadCellProps = {
              align: 'right',
            }
            col.muiTableBodyCellProps = {
              align: 'right',
              sx:{
                maxWidth: 140,
                textWrap: "wrap"
              }
            }
          }
        })
      })
      break;
    case 5:
      dataTable.forEach((tab, index)=>{
        if(index === 0){
          tab.short = {
            id: "pa", 
            desc: false,
          }
        }else if(index === 1){
          tab.short = {
            id: tab.colorfor, 
            desc: false,
          }
        }else if(index === 2){
          tab.short = {
            id: "hfb", 
            desc: false,
          }
        }else if(index === 3){
          tab.short = {
            id: tab.colorfor, 
            desc: false,
          }
        }
        

        tab.data.forEach((row)=> {
          if(row.store === store || row.hfb === hfb || row.pa === pa) row.selected = true
        })

        tab.columns.forEach((col, index)=>{
          col.accessorKey = col.key
          col.header = col.label
          if(col.key === tab.colorfor){
            col.Cell = ({cell, row}) => <span 
              style={{color: cell.column.id === tab.colorfor ?  row.original.value_color : '#000',
              }}>
              <b>{cell.getValue()}</b>
            </span>
            
          }
          if(index === 0){
            col.size = 60
          }else{
            col.size = 120
            col.muiTableHeadCellProps = {
              align: 'right',
            }
            col.muiTableBodyCellProps = {
              align: 'right',
              sx:{
                maxWidth: 140,
                textWrap: "wrap"
              }
            }
          }
        })
      })
      break;
    case 7:
      dataTable.forEach((tab)=>{
        tab.data.forEach((row)=> {
          if(row.store === store || row.category === categoryProductivity ) row.selected = true
        })

        tab.columns.forEach((col, index)=>{
          col.accessorKey = col.key
          col.header = col.label
          if(col.key === tab.colorfor){
            col.Cell = ({cell, row}) => <span 
              style={{color: cell.column.id === tab.colorfor ?  row.original.value_color : '#000',
              }}>
              <b>{cell.getValue()}</b>
            </span>
          
          }

          
          if(index === 0 ){
            col.muiTableHeadCellProps={
              sx: {
                minWidth: 60,
                width: 60,
                maxWidth: 60,
              },
            }
            col.muiTableBodyCellProps={
              sx: {
                minWidth: 60,
                width: 60,
                maxWidth: 60,
              },
            }
          }
          else{
            col.muiTableHeadCellProps={
              sx: {
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                textWrap: "wrap"
              },
            }
            col.muiTableBodyCellProps={
              sx: {
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                textWrap: "wrap"
              },
            }
          }
        })
      })
      break;
    default:
      break;
  }
  return dataTable
}


export function ColumnsHeadTable(flow){
  let columnsHead = []
  switch(flow.id){
    case 2:
      columnsHead = [
        {
          accessorKey: "label", 
          header: "",
          size: 70
        },
        {
          accessorKey: "ctry", 
          header: "CTRY",
          size: 40,
          Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>,
        },
        {
          accessorKey: "sto",
          header: "STO",
          size: 40,
          Cell: ({cell, row}) => <span 
          style={{color: cell.column.id === 'sto' ? row.original.sto_color : '#000'
                            }}>
                            <b>{cell.getValue()}</b>
                            </span>,
        },
        {
          accessorKey: "hfb",
          header: "HFB",
          size: 40,
          Cell: ({cell, row}) => <span 
          style={{color: cell.column.id === 'hfb' ? row.original.hfb_color : '#000'
                            }}>
                            <b>{cell.getValue()}</b>
                            </span>,
        }
      ]
      break;
    case 3:
      columnsHead = [
        {
          accessorKey: "label", 
          header: "",
          size: 70,
          //hide header
          muiTableHeadCellProps:{
            sx: {
              '& .Mui-TableHeadCell-Content': {
                display: 'none',
              },
              padding: 0,
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: "none"
            },
          },
        },
        {
          accessorKey: "value",
          header: "",
          size: 40,
          //hide header
          muiTableHeadCellProps:{
            sx: {
              '& .Mui-TableHeadCell-Content': {
                display: 'none',
              },
              padding: 0,
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: "none"
            },
          },
          Cell: ({cell, row}) => <span 
          style={{color: row.original.color ? row.original.color : '#000'
                            }}>
                            <b>{cell.getValue()}</b>
                            </span>,
        },
        
      ]
      break;
    case 4:
      columnsHead = [
        {
          accessorKey: "label", 
          header: "",
          size: 70
        },
        {
          accessorKey: "ctry", 
          header: "CTRY",
          size: 40,
          Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>,
        },
        {
          accessorKey: "sto",
          header: "STO",
          size: 40,
          Cell: ({cell, row}) => <span 
          style={{color: cell.column.id === 'sto' ? row.original.sto_color : '#000'
                            }}>
                            <b>{cell.getValue()}</b>
                            </span>,
        },
        {
          accessorKey: "hfb",
          header: "HFB",
          size: 40,
          Cell: ({cell, row}) => <span 
          style={{color: cell.column.id === 'hfb' ? row.original.hfb_color : '#000'
                            }}>
                            <b>{cell.getValue()}</b>
                            </span>,
        }
      ]
      break;
    case 5:
      columnsHead = [
        {
          accessorKey: "label", 
          header: "",
          size: 70
        },
        {
          accessorKey: "ctry", 
          header: "CTRY",
          size: 40,
          Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>,
        },
        {
          accessorKey: "sto",
          header: "STO",
          size: 40,
          Cell: ({cell, row}) => <span 
          style={{color: cell.column.id === 'sto' ? row.original.sto_color : '#000'
                            }}>
                            <b>{cell.getValue()}</b>
                            </span>,
        },
      ]
      break;
    case 7:
      columnsHead = [
        {
          accessorKey: "label", 
          header: "",
          size: 70
        },
        {
          accessorKey: "ctry", 
          header: "CTRY",
          size: 40,
          Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>,
        },
        {
          accessorKey: "sto",
          header: "STO",
          size: 40,
          Cell: ({cell, row}) => <span 
          style={{color: cell.column.id === 'sto' ? row.original.sto_color : '#000'
                            }}>
                            <b>{cell.getValue()}</b>
                            </span>,
        }
      ]
      break;
    default:
      break;
  }
  
  return columnsHead
}