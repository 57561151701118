import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { doLogout } from "../store/globalSlice";

export default function PrivateRoutes({children}) {
  const dispatch = useDispatch()
  const isLogged = !!localStorage.getItem('login')
  if(!isLogged) dispatch(doLogout)
  return (isLogged)
  ? children
  : <Navigate to="/login" />
}
