
import Check from "../form/Check"
import Text from "../content/Text"
import style from "../../assets/scss/components/global/articleCard.module.scss"
import noImage from "../../assets/img/image_not_supported.png"
import { useTranslation } from "react-i18next";
import Accordion, { AccordionItem } from "@ingka/accordion";
import Table from "../content/Table";
import { RETURNS_ID } from "../../utils/flowsIds";

export default function ArticleCard({props, handleChange, flow}) {
  const [t] = useTranslation("global")
  const columns = [
    {
      accessorKey: "reason", 
      header: "",
      size: 190,
      muiTableBodyCellProps: {
        sx:{
          maxWidth: 200,
          textWrap: "wrap"
        }
      },
    },
    {
      accessorKey: "propstore", 
      header: "STO",
      size: 40,
      Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>
    },
    {
      accessorKey: "propcountry",
      header: "CTRY",
      size: 40,
      Cell: ({cell}) => <span><b>{cell.getValue()}</b></span>
    }
  ]
  return (
    <div className={style.article}>
      <div className={style.header}>
        <div className={style.ref}>
          {props.item}
        </div>
        <Check 
        formField={{
          style:{margin: 0},
          fieldHelper:{
            id: 'helper-msg-id',
            /* msg: props.state !== "" ? t('global.state')+': '+props.state : "",  */
            type: ''
          }
        }}
        check={{
          onChange:handleChange,
          value: props.item,
          id: props.item,
          name: props.item
        }}
        />
        
      </div>
      <div className={style.body}>

        <div className={style.content}>
          <Text
            tagName="h3" 
            headingSize="m">
              {props.iteminfo.name}
          </Text>
          <Text
            tagName="p" 
            bodySize="m">
              {props.iteminfo.description}
          </Text>
          <Text
            className="my-1"
            tagName="p" 
            bodySize="m">
              {flow.id !== 5 ? (<>{t('new-action.average')} : <b>{props.venta_media}</b></>)
              : (<>{t('new-action.returns')} : <b>{props.qty_returns}</b></>)
            }
              
          </Text>
        </div>
        <div className={style.image}>
          <img src={props.iteminfo.image !== "" ? props.iteminfo.image : noImage} alt="Product" />
        </div>
      </div>

      <div className={style.data}>
        <div>
          <Text
            tagName="p" 
            bodySize="s">
              <span className={`${style.ball} ${style[props.stock]}`}></span>{t('new-action.inStock')}
          </Text>
          <Text
            tagName="p" 
            bodySize="s">
              <span className={`${style.ball} ${style[props.GM0]}`}></span>{flow.id !== 4 ? "GM0" : "Profit"}
          </Text>
        </div>
        <div>
        <Text
            tagName="p" 
            bodySize="s">
              {t('new-action.potentialUd')}: <b>{props.potential_units}</b>
          </Text>
          <Text
            tagName="p" 
            bodySize="s">
              {t('new-action.potentialE')}: <b>{props.potential_sales}</b>
          </Text>
        </div>
      </div>

      {flow.id === RETURNS_ID &&
      <Accordion>
        <AccordionItem
        className={style.accordion}
        caption={t("new-action.reason")}
        id={props.idTask}
        >
          <Table columns={columns} data={props.reasons}/>
        </AccordionItem>
      </Accordion>
      }
    </div>
  )
}
